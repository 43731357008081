import DesktopHeader from './Headers/DesktopHeader';
import Footer from './Footer';
import Meta from './Meta';

export default function Layout(props) {
  const { children, className = '', isFooter = false } = props;

  return (
    <>
      <Meta />
      <main className={`${className} min-h-screen`}>
        <DesktopHeader />
        {children}
      </main>
      {isFooter && <Footer />}
    </>
  );
}
