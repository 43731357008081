import { forwardRef, useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { MOBILE_MAX_WIDTH } from 'Lib/constants/settings';
import { PATH } from 'Lib/constants/path';
import classNames from 'classnames';
import styles from 'Styles/modules/search.module.scss';
import { useRouter } from 'next/router';
import { useSearchBox } from 'react-instantsearch-hooks-web';

const Hit = forwardRef((props,ref) => {
  const { hit, className, handleClick } = props;
  const { query, refine: setQuery, clear } = useSearchBox();
  const router = useRouter();
  const {
    product_image,
    product_name,
    regular_price,
    sale_price,
    on_sale,
    slug
  } = hit;
  const [screenWidth, setScreenWidth] = useState(null);

  useEffect(() => {
    setScreenWidth(window.screen.width);

    return () => {
      setScreenWidth(null);
    }
  }, []);

  return (
    <article className={classNames("hit cursor-pointer", styles.item_hover)} ref={ref}>
      <Link href={`${PATH.PRODUCT}/${slug}`}>
        <a onClick={(e) => handleClick(e, slug)}>
        <div className={classNames("flex p-2", className)}>
          <header className="hit-image-container">

            <span className={`${styles.hit_image}`}>
              <Image
                src={product_image}
                alt={product_name}
                height={screenWidth < MOBILE_MAX_WIDTH ? 90 : 40}
                width={screenWidth < MOBILE_MAX_WIDTH ? 90 : 40}
              />
            </span>

          </header>
          <div className="pl-4 text-left">
            {product_name && (
              <span className={styles.product_name}>{product_name}</span>
            )}
            <br />
            {on_sale ? (
              <>
                <span className={styles.product_price}>{parseInt(sale_price).toLocaleString('en-US')} đ</span>
                <span className={styles.product_sale_price}>
                  {parseInt(regular_price).toLocaleString('en-US')} đ
                </span>
              </>
            ):(
              <span className={styles.product_price}>{parseInt(regular_price).toLocaleString('en-US')} đ</span>
            )}
            <br />
          </div>
        </div>
      </a>
    </Link>
    </article >
  );
});

Hit.displayName = "Hit";

export default Hit;