import { DEBOUNCE_TIME, MINIMUM_SEARCH_LENGTH } from 'Lib/constants/settings';
import { useCallback, useEffect, useRef, useState } from 'react';

import { PATH } from 'Lib/constants/path';
import SearchHits from './SearchHits';
import { debounce } from 'lodash';
import { isDesktop } from 'react-device-detect';
import isEmpty from 'utils/isEmpty';
import { useRouter } from 'next/router';
import { useSearchBox } from 'react-instantsearch-hooks-web';

export default function CustomSearchBox(props) {
  const {
    className,
    onFocus,
    resetIconComponent,
    placeholder,
    searchIconComponent
  } = props;

  const { query, refine: setQuery, clear } = useSearchBox();
  const [suggestionIdx, setSuggestionIdx] = useState(-1);
  const [showSuggestion, setShowSuggestion] = useState(false)
  const [inputVal, setInputVal] = useState(query);
  const itemRefs = useRef([])
  const router = useRouter();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((val) =>
      setQuery(val)

      , DEBOUNCE_TIME),
    []
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setSuggestionIdx(-1)
    setInputVal(value);

    if (value.length == 0) {
      clear();
    }

    if (value.length >= MINIMUM_SEARCH_LENGTH) {
      setShowSuggestion(true)
      debounceSearch(value.trim());
    }
  };

  const handleReset = () => {
    setInputVal('');
    clear();
  };

  const handleBlur = (e) => {
    if (!e.relatedTarget) {
      setShowSuggestion(false)
    }
  }
  const handleFocus = () => {
    setShowSuggestion(true)
  }

  const handleKeyDown = (evt) => {
    if (itemRefs.current.length > 0) {
      if (evt.key === "ArrowDown") {
        if (suggestionIdx === itemRefs.current.length - 1) {
          return
        }

        itemRefs.current[suggestionIdx + 1]?.element?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' })
        setSuggestionIdx(suggestionIdx + 1)
      }

      if (evt.key === "ArrowUp") {
        if (suggestionIdx === -1) {
          return;
        }

        itemRefs.current[suggestionIdx - 1]?.element?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' })
        setSuggestionIdx(suggestionIdx - 1);
      }


      if (evt.key === "Enter") {
        if (suggestionIdx === -1 && !isEmpty(inputVal)) {
          setSuggestionIdx(-1)
          setShowSuggestion(false)
          clear();
          router.push(
            `${PATH.SEARCH}/?q=${inputVal}`
          )
          return
        } else if (suggestionIdx >= 0) {
          reset()
          router.push(
            `${PATH.PRODUCT}/${itemRefs.current[suggestionIdx].slug}`
          )
        }
      }
    }
  }

  useEffect(() => {
    return () => reset()
  }, [])

  const reset = () => {
    setSuggestionIdx(-1)
    setShowSuggestion(false)
    setInputVal('');
    clear();
  }

  const handleClick = (e, slug) => {
    e.preventDefault();
    reset()
    router.push(
      `${PATH.PRODUCT}/${slug}`
    )
  }

  return (
    <div className={className}>
      <span>{searchIconComponent}</span>
      <input
        type="search"
        value={inputVal}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        id="instant-search"
        autoComplete="off"
        autoCorrect='off'
      />
      {inputVal.length > 0 && (
        <button onClick={handleReset}>{resetIconComponent}</button>
      )}
      {showSuggestion && isDesktop ?
        <SearchHits
          suggestionIdx={suggestionIdx}
          searchKeyword={inputVal}
          ref={itemRefs}
          handleClick={handleClick}
        /> : null}
    </div>
  );
}
