export const CMS_NAME = 'Xixon Perfume';
export const CMS_URL = 'https://xixonshop.net';
export const HOME_OG_IMAGE_URL =
    'https://xixonshop.net/fb_common.png';

export const BANKINFO = {
    name: 'Nguyễn Thị Mỹ Dung',
    bank: 'VCB - Ngân hàng TMCP Ngoại Thương',
    account: '0331000426335',
    content: '[Tên] [Số điện thoại]'
};

export const PAYMENT_METHOD = {
    BANK_TRANSFER: 'bacs',
    VNPAY: 'vnpay'
};

export const SHOP_INFO = {
    ADDRESS: '',
    PHONE: '0898 318 328',
    MAIL: 'tuvan@xixonshop.net',
    YOUTUBE: '',
    FACEBOOK: 'https://fb.com/xixonshopperfume',
    FB_MES: 'https://m.me/xixonshopperfume',
    INSTA: 'https://instagram.com/xixonperfumee'
}

export const HOTLINE = '0898 318 328';
export const MOBILE_MAX_WIDTH = 960;
export const MANIFEST_INTERVAL = 60000;
export const DEBOUNCE_TIME = 350;
export const MINIMUM_SEARCH_LENGTH = 2;
