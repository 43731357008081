import { CMS_NAME, HOME_OG_IMAGE_URL } from 'Lib/constants/settings';

import Head from 'next/head';

export default function Meta() {
  return (
    <Head>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="rgb(223,57,80)"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
      />
      <meta name="msapplication-TileColor" content="rgb(223,57,80)" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="rgb(223,57,80)" />
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />
      <link rel="canonical" href={process.env.SITE_URL} key="canonical" />

      <meta property="fb:admins" content="1387966484" />
      <meta property="fb:app_id" content="544092155705817" />
      <meta
        name="description"
        content={`Nhà bán lẻ nước hoa chính hãng ${CMS_NAME}.`}
      />
      <meta property="og:image" content={HOME_OG_IMAGE_URL} key="ogimage" />
    </Head>
  );
}
