export const MOCK_SEARCH_RESULT = {
  hits: [
    {
      "product_name": "Nước hoa Paco Rabanne One Million Prive",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-One-Million-Prive-5-324x324.jpg",
      "short_description": "",
      "regular_price": "1900000",
      "sale_price": "1900000",
      "on_sale": false,
      "slug": "nuoc-hoa-paco-rabanne-one-million-prive-edp-100ml",
      "objectID": "995",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa __ais-highlight__Pac__/ais-highlight__o Rabanne One Million Prive",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-One-Million-Prive-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1900000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1900000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-__ais-highlight__pac__/ais-highlight__o-rabanne-one-million-prive-edp-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa Paco Rabanne Invictus Aqua",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Invictus-Aqua-1-324x324.jpg",
      "short_description": "",
      "regular_price": "1700000",
      "sale_price": "1700000",
      "on_sale": false,
      "slug": "nuoc-hoa-paco-rabanne-invictus-aqua-edt-100ml",
      "objectID": "983",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa __ais-highlight__Pac__/ais-highlight__o Rabanne Invictus Aqua",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Invictus-Aqua-1-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1700000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1700000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-__ais-highlight__pac__/ais-highlight__o-rabanne-invictus-aqua-edt-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa Paco Rabanne Lady Million",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Lady-Million-5-324x324.jpg",
      "short_description": "",
      "regular_price": "1700000",
      "sale_price": "1700000",
      "on_sale": false,
      "slug": "nuoc-hoa-paco-rabanne-lady-million-edp-80ml",
      "objectID": "939",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa __ais-highlight__Pac__/ais-highlight__o Rabanne Lady Million",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Lady-Million-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1700000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1700000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-__ais-highlight__pac__/ais-highlight__o-rabanne-lady-million-edp-80ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa Paco Rabanne Invictus",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Invictus-5-324x324.jpg",
      "short_description": "",
      "regular_price": "2350000",
      "sale_price": "2350000",
      "on_sale": false,
      "slug": "nuoc-hoa-paco-rabanne-invictus-edt-100ml",
      "objectID": "924",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa __ais-highlight__Pac__/ais-highlight__o Rabanne Invictus",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Invictus-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "2350000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "2350000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-__ais-highlight__pac__/ais-highlight__o-rabanne-invictus-edt-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa Paco Rabanne One Million",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-One-Million-6-324x324.jpg",
      "short_description": "",
      "regular_price": "1970000",
      "sale_price": "",
      "on_sale": false,
      "slug": "nuoc-hoa-paco-rabanne-one-million-edt-100ml",
      "objectID": "280",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa __ais-highlight__Pac__/ais-highlight__o Rabanne One Million",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-One-Million-6-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1970000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-__ais-highlight__pac__/ais-highlight__o-rabanne-one-million-edt-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nữ Paco Rabanne Olympea",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Paco-Rabanne-Olympea-2-324x324.jpg",
      "short_description": "",
      "regular_price": "1650000",
      "sale_price": "1650000",
      "on_sale": false,
      "slug": "nuoc-hoa-nu-paco-rabanne-olympea-edp-80ml",
      "objectID": "700",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nữ __ais-highlight__Pac__/ais-highlight__o Rabanne Olympea",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/__ais-highlight__Pac__/ais-highlight__o-Rabanne-Olympea-2-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1650000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1650000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nu-__ais-highlight__pac__/ais-highlight__o-rabanne-olympea-edp-80ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nam Paco Rabanne One Million Parfum",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-1-Million-Parfum-6-324x324.jpg",
      "short_description": "",
      "regular_price": "2300000",
      "sale_price": "2300000",
      "on_sale": false,
      "slug": "nuoc-hoa-nam-paco-rabanne-1-million-parfum-100ml",
      "objectID": "4564",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nam __ais-highlight__Pac__/ais-highlight__o Rabanne One Million Parfum",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-1-Million-Parfum-6-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "2300000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "2300000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nam-__ais-highlight__pac__/ais-highlight__o-rabanne-1-million-parfum-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nữ Paco Rabanne Olympea Blossom Florale",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Olympea-Blossom-Florale-1-324x324.jpg",
      "short_description": "",
      "regular_price": "2500000",
      "sale_price": "2500000",
      "on_sale": false,
      "slug": "nuoc-hoa-nu-paco-rabanne-olympea-blossom-edp-florale-80ml",
      "objectID": "4558",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nữ __ais-highlight__Pac__/ais-highlight__o Rabanne Olympea Blossom Florale",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Olympea-Blossom-Florale-1-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "2500000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "2500000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nu-__ais-highlight__pac__/ais-highlight__o-rabanne-olympea-blossom-edp-florale-80ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nam Paco Rabanne Phantom",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Phantom-5-324x324.jpg",
      "short_description": "",
      "regular_price": "2600000",
      "sale_price": "2600000",
      "on_sale": false,
      "slug": "nuoc-hoa-nam-paco-rabanne-phantom-edt-100ml",
      "objectID": "4534",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nam __ais-highlight__Pac__/ais-highlight__o Rabanne Phantom",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Phantom-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "2600000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "2600000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nam-__ais-highlight__pac__/ais-highlight__o-rabanne-phantom-edt-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nữ Paco Rabanne Olympea Aqua",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Olympea-Aqua-6-324x324.jpg",
      "short_description": "",
      "regular_price": "1500000",
      "sale_price": "1500000",
      "on_sale": false,
      "slug": "nuoc-hoa-nu-paco-rabanne-olympea-aqua-edt-80ml",
      "objectID": "3966",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nữ __ais-highlight__Pac__/ais-highlight__o Rabanne Olympea Aqua",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Olympea-Aqua-6-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1500000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1500000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nu-__ais-highlight__pac__/ais-highlight__o-rabanne-olympea-aqua-edt-80ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nữ Paco Rabanne Lady Million Empire",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Lady-Million-Empire-5-324x324.jpg",
      "short_description": "",
      "regular_price": "2050000",
      "sale_price": "",
      "on_sale": false,
      "slug": "nuoc-hoa-nu-paco-rabanne-lady-million-empire-edp-80ml",
      "objectID": "3960",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nữ __ais-highlight__Pac__/ais-highlight__o Rabanne Lady Million Empire",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Lady-Million-Empire-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "2050000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nu-__ais-highlight__pac__/ais-highlight__o-rabanne-lady-million-empire-edp-80ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nữ Paco Rabanne Black XS",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Black-XS-EDP-80ml-4-324x324.jpg",
      "short_description": "",
      "regular_price": "1850000",
      "sale_price": "1850000",
      "on_sale": false,
      "slug": "nuoc-hoa-nu-paco-rabanne-black-xs-edp-80ml",
      "objectID": "3894",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nữ __ais-highlight__Pac__/ais-highlight__o Rabanne Black XS",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Black-XS-EDP-80ml-4-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1850000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1850000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nu-__ais-highlight__pac__/ais-highlight__o-rabanne-black-xs-edp-80ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nam Paco Rabanne Pure XS Night",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Pure-XS-Night-5-324x324.jpg",
      "short_description": "",
      "regular_price": "1890000",
      "sale_price": "1890000",
      "on_sale": false,
      "slug": "nuoc-hoa-nam-paco-rabanne-pure-xs-night-edp-100ml",
      "objectID": "3747",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nam __ais-highlight__Pac__/ais-highlight__o Rabanne Pure XS Night",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Pure-XS-Night-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1890000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1890000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nam-__ais-highlight__pac__/ais-highlight__o-rabanne-pure-xs-night-edp-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nữ Paco Rabanne Olympea Onyx Collector Edition",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Olympea-Onyx-Collector-Edition-5-324x324.jpg",
      "short_description": "",
      "regular_price": "2130000",
      "sale_price": "2130000",
      "on_sale": false,
      "slug": "nuoc-hoa-nu-paco-rabanne-olympea-onyx-collector-edition-edp-80ml",
      "objectID": "3497",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nữ __ais-highlight__Pac__/ais-highlight__o Rabanne Olympea Onyx Collector Edition",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Olympea-Onyx-Collector-Edition-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "2130000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "2130000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nu-__ais-highlight__pac__/ais-highlight__o-rabanne-olympea-onyx-collector-edition-edp-80ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nam Paco Rabanne Invictus Onyx Collector Edition",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Invictus-Onyx-Collector-Edition-5-324x324.jpg",
      "short_description": "",
      "regular_price": "1900000",
      "sale_price": "1900000",
      "on_sale": false,
      "slug": "nuoc-hoa-nam-paco-rabanne-invictus-onyx-collector-edition-edt-100ml",
      "objectID": "3464",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nam __ais-highlight__Pac__/ais-highlight__o Rabanne Invictus Onyx Collector Edition",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Invictus-Onyx-Collector-Edition-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1900000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1900000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nam-__ais-highlight__pac__/ais-highlight__o-rabanne-invictus-onyx-collector-edition-edt-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nam Paco Rabanne Invictus Legend",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Invictus-Legend-5-324x324.jpg",
      "short_description": "",
      "regular_price": "1850000",
      "sale_price": "1850000",
      "on_sale": false,
      "slug": "nuoc-hoa-nam-paco-rabanne-invictus-legend-edp-100ml",
      "objectID": "3342",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nam __ais-highlight__Pac__/ais-highlight__o Rabanne Invictus Legend",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Invictus-Legend-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1850000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1850000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nam-__ais-highlight__pac__/ais-highlight__o-rabanne-invictus-legend-edp-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nữ Paco Rabanne Olympea Legend",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Olympea-Legend-5-324x324.jpg",
      "short_description": "",
      "regular_price": "1950000",
      "sale_price": "1950000",
      "on_sale": false,
      "slug": "nuoc-hoa-nu-paco-rabanne-olympea-legend-edp-80ml",
      "objectID": "3269",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nữ __ais-highlight__Pac__/ais-highlight__o Rabanne Olympea Legend",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Olympea-Legend-5-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1950000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1950000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nu-__ais-highlight__pac__/ais-highlight__o-rabanne-olympea-legend-edp-80ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Bộ quà tặng Paco Rabanne Olympea nước hoa 80ml + 10ml",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Bo-qua-tang-Paco-Rabanne-Olympea-nuoc-hoa-80ml-10ml-324x324.png",
      "short_description": "",
      "regular_price": "1900000",
      "sale_price": "1900000",
      "on_sale": false,
      "slug": "bo-qua-tang-paco-rabanne-olympea-nuoc-hoa-80ml-10ml",
      "objectID": "3233",
      "_highlightResult": {
        "product_name": {
          "value": "Bộ quà tặng __ais-highlight__Pac__/ais-highlight__o Rabanne Olympea nước hoa 80ml + 10ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Bo-qua-tang-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Olympea-nuoc-hoa-80ml-10ml-324x324.png",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1900000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1900000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "bo-qua-tang-__ais-highlight__pac__/ais-highlight__o-rabanne-olympea-nuoc-hoa-80ml-10ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nữ Paco Rabanne Lady Million Lucky",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Lady-Million-Lucky-4-324x324.jpg",
      "short_description": "",
      "regular_price": "1650000",
      "sale_price": "1650000",
      "on_sale": false,
      "slug": "nuoc-hoa-nu-paco-rabanne-lady-million-lucky-edp-80ml",
      "objectID": "2751",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nữ __ais-highlight__Pac__/ais-highlight__o Rabanne Lady Million Lucky",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Lady-Million-Lucky-4-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1650000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1650000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nu-__ais-highlight__pac__/ais-highlight__o-rabanne-lady-million-lucky-edp-80ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    },
    {
      "product_name": "Nước hoa nam Paco Rabanne Pure XS Pour Homme",
      "product_image": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-Paco-Rabanne-Pure-XS-Pour-Homme-1-324x324.jpg",
      "short_description": "",
      "regular_price": "1800000",
      "sale_price": "1800000",
      "on_sale": false,
      "slug": "nuoc-hoa-nam-paco-rabanne-pure-xs-pour-homme-edt-100ml",
      "objectID": "2727",
      "_highlightResult": {
        "product_name": {
          "value": "Nước hoa nam __ais-highlight__Pac__/ais-highlight__o Rabanne Pure XS Pour Homme",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "product_image": {
          "value": "https://shop.muarauantoan.com/wp-content/uploads/2022/06/Nuoc-hoa-__ais-highlight__Pac__/ais-highlight__o-Rabanne-Pure-XS-Pour-Homme-1-324x324.jpg",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        },
        "short_description": {
          "value": "",
          "matchLevel": "none",
          "matchedWords": []
        },
        "regular_price": {
          "value": "1800000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "sale_price": {
          "value": "1800000",
          "matchLevel": "none",
          "matchedWords": []
        },
        "slug": {
          "value": "nuoc-hoa-nam-__ais-highlight__pac__/ais-highlight__o-rabanne-pure-xs-pour-homme-edt-100ml",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "pac"
          ]
        }
      }
    }
  ]
};
