import { MOCK_SEARCH_RESULT } from 'Lib/mock-search';
import algoliasearch from 'algoliasearch';

const algoliaClient = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_PUBLIC_API_KEY,
  {
    _useRequestCache: true
  }
);

const ENABLE_MOCK_SEARCH_RESULT=false;

export const searchClient = {
  search(requests) {
    const shouldSearch = !ENABLE_MOCK_SEARCH_RESULT && requests.some(({ params: { query } }) => query !== '');
    if (shouldSearch) {
      return algoliaClient.search(requests);
    }
    return Promise.resolve({
      results: [{ hits: ENABLE_MOCK_SEARCH_RESULT ? MOCK_SEARCH_RESULT.hits : [] }]
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues
};
