import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import {
  search_input,
  search_input_wrapper
} from 'Styles/modules/search.module.scss';

import CustomSearchBox from './CustomSearchBox';
import { searchClient } from 'utils/searchClient';

export default function DesktopSearch() {

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.ALGOLIA_INDEX_NAME}
    >
      {/* <Configure hitsPerPage={20} page={0} /> */}
      <div className={search_input_wrapper}>
        <CustomSearchBox
          className={search_input}
          placeholder="Tìm sản phẩm yêu thích"
          searchIconComponent={
            <SearchIcon className="absolute left-2 top-2 h-5 w-5 stroke-1 text-slate-500" />
          }
          resetIconComponent={
            <XIcon className="absolute right-2 top-2 h-5 w-5 stroke-1 text-slate-500" />
          }
        />
      </div>
    </InstantSearch>
  );
}
