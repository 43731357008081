import { icon, number, wrapper } from 'Styles/modules/cart.module.scss';

import { AppContext } from 'Context/AppContext';
import Link from 'next/link';
import { PATH } from 'Lib/constants/path';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useContext } from 'react';

export default function MiniCart({ className, iconComponent, numberClasses }) {
  const [cart] = useContext(AppContext);
  const productCount =
    null !== cart && Object.keys(cart).length ? cart.totalProductsCount : '';

  return (
    <div className={classNames(wrapper, className)}>
      <Link href={PATH.CART}>
        <a className={icon} alt="View cart" aria-label='View Cart'>
          {iconComponent ? iconComponent : <ShoppingCartIcon />}
          {productCount && (
            <span
              className={classNames(number, numberClasses)}
              dangerouslySetInnerHTML={{ __html: productCount }}
            />
          )}
        </a>
      </Link>
    </div>
  );
}
