import {
  hit_wrapper,
  item_hover
} from 'Styles/modules/search.module.scss';

import Hit from 'Components/Algolia/Hit';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { useHits } from 'react-instantsearch-hooks-web';

const SearchHits = forwardRef((props, ref) => {
  const { hits } = useHits(props);
  const { suggestionIdx, handleClick } = props

  return (
    <div className={classNames(hit_wrapper, "bg-white")}>

      {/* <div className={classNames(keyword, item_hover)}>
          <span className={title}>Tìm kiếm với từ khoá </span><span className='text-slate-800 italic'>&quot;{searchKeyword}&quot;</span>
        </div> */}
      <div>
        {hits.filter(e => e.sale_price != false).map((elem, index) =>
          <Hit
            hit={elem}
            key={index}
            className={classNames(index === suggestionIdx ? `bg-slate-100` : '', `search-${index}`, item_hover)}
            ref={(el) => ref.current[index] = {element: el, slug: elem.slug}}
            handleClick={handleClick}
          />
        )}
      </div>
      {/* {hits.length > 0 ? <div className={
          classNames(" text-xs py-2 text-center fixed w-full bg-white bottom-0 text-primary_color-0 cursor-pointer")}>
          Xem thêm {results.nbHits} sản phẩm</div> : null} */}

    </div>
  )
})

SearchHits.displayName = "SearchHits";

export default SearchHits;