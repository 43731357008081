import { FacebookIcon, InstagramIcon, YouTubeIcon } from './SVG/MoreSVG';
import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
  ShoppingBagIcon
} from '@heroicons/react/outline';
import { contact_info, item } from 'Styles/modules/footer.module.scss';

import Container from './Container';
import Link from 'next/link';
import { SHOP_INFO } from 'Lib/constants/settings';
import classNames from 'classnames';

export default function Footer() {
  return (
    <footer className="bg-accent-1 border-t text-white">
      <Policy />
      <div className="bottom bg-slate-900 px-4">
        <Contact />
      </div>
    </footer>
  );
}

const Policy = () => {
  return (
    <div className="bg-slate-900 py-1 border-b border-gray-600">
      <Container className="flex flex-grow">
        <div className={item}>
          <Link href={'/content/dieu-khoan-su-dung'}>
            <a>
              <span>Điều khoản sử dụng</span>
            </a>
          </Link>
        </div>
        <div className={item}>
          <Link href={'/content/chinh-sach-doi-tra'}>
            <a>
              <span>Quy định trả hàng</span>
            </a>
          </Link>
        </div>
        <div className={item}>
          <Link href={'/content/chinh-sach-bao-mat'}>
            <a>
              <span>Chính sách bảo mật</span>
            </a>
          </Link>
        </div>
        <div className={item}>
          <Link href={'/content/chinh-sach-giao-hang'}>
            <a>
              <span>Phương thức vận chuyển</span>
            </a>
          </Link>
        </div>
      </Container>
    </div>
  );
};

const Contact = () => {
  return (
    <div>
      <Container className={classNames(contact_info)}>
        <aside className="contact mb-4">
          <h3 className="mb-4">Địa chỉ cửa hàng</h3>
          <p className="flex">
            <span className="mr-2">
              <LocationMarkerIcon className="h-6 w-6 stroke-1" />
            </span>
            <span>{SHOP_INFO.ADDRESS}</span>
          </p>
          <p className="flex">
            <span className="mr-2">
              <PhoneIcon className="h-6 w-6 stroke-1" />
            </span>
            <span>
              <a href={`tel:${SHOP_INFO.PHONE.replace(' ', '')}`}>{SHOP_INFO.PHONE}</a>
            </span>
          </p>
          <p className="flex">
            <span className="mr-2">
              <MailIcon className="h-6 w-6 stroke-1" />
            </span>
            <span>{SHOP_INFO.MAIL}</span>
          </p>
        </aside>
        <aside className="bottom-menu flex-col hidden lg:flex">
          <Link href={`/content/gioi-thieu`}>
            <a>Giới thiệu</a>
          </Link>
          <Link href={`/content/chinh-sach-giao-hang`}>
            <a>Phương thức vận chuyển</a>
          </Link>
          <Link href={`/content/dieu-khoan-su-dung`}>
            <a>Điểu khoản sử dụng</a>
          </Link>
          <Link href={`/content/chinh-sach-bao-mat`}>
            <a>Chính sách bảo mật</a>
          </Link>
          <Link href={`/content/chinh-sach-gia`}>
            <a>Chính sách giá</a>
          </Link>
          <Link href={`/content/chinh-sach-doi-tra`}>
            <a>Chính sách đổi trả</a>
          </Link>
        </aside>

        <aside className='social'>
          <span className="footer-title">Social</span>
          <div className="grid grid-flow-col gap-4">
            <Link href={SHOP_INFO.FACEBOOK}>
              <a aria-label='Go to facebook page'>
                <FacebookIcon />
              </a>
            </Link>
            <Link href={SHOP_INFO.INSTA}>
              <a aria-label='Go to #instagram'>
                <InstagramIcon />
              </a>
            </Link>
          </div>
        </aside>
      </Container>
    </div>

  );
};
