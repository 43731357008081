/* eslint-disable @next/next/inline-script-id */
import { ShoppingBagIcon } from '@heroicons/react/outline';
import {
  desktop_header,
  headline,
  headline_inner,
  menu,
  menu_wrapper
} from 'Styles/modules/desktop-header.module.scss';
import { useContext, useEffect, useState } from 'react';

import MiniCart from 'Components/Cart/MiniCart';
import DesktopSearch from 'Components/Search/DesktopSearch';
import { MenuContext } from 'Context/MenuContext';
import { HOTLINE } from 'Lib/constants/settings';
import logo from 'img/logo_f.png';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';

const FacebookChat = dynamic(() => import('Components/FacebookChat'))

const DesktopHeader = () => {
  const { sideMenuItems } = useContext(MenuContext);
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    setMenuItems(sideMenuItems)

    return () => {
      setMenuItems([])
    }
  }, [sideMenuItems])
  const isProduction = process.env.NODE_ENV === 'production'

  return (
    <div className="hidden lg:block">
      <div className={headline}>
        <div className={headline_inner}>
          <span>Thương hiệu nước hoa uy tín từ 2015</span>
          <div>
            <span>Freeship mọi đơn hàng</span>
          </div>
        </div>
      </div>
      <header className={desktop_header}>
        <nav className="items-center">
          <Link href="/" passHref>
            <a>
              <Image height={33} width={183} alt="logo" src={logo} />
            </a>
          </Link>
          <DesktopSearch />
          <div className="flex items-center justify-center text-center">
            {/* <PhoneIcon className='stroke-1 h-6 w-6' /> */}
            <div className="ml-12">
              <p className="font-medium">{HOTLINE}</p>
              <p className="text-sm">Tổng đài CSKH</p>
            </div>
          </div>

          <MiniCart
            className="ml-auto"
            iconComponent={<ShoppingBagIcon className="h-8 w-8 stroke-1" />}
          />
        </nav>
        <div className={menu_wrapper}>
          <div className={menu}>
            {menuItems &&
              menuItems.map((elem) => (
                <Link
                  key={elem.id}
                  href={{
                    pathname: elem.path
                  }}
                >
                  <a>{elem.name}</a>
                </Link>
              ))}
          </div>
        </div>
      </header>
      {/* {isProduction ?<FacebookChat /> : null} */}
      {
        <div>
          <div id="fb-root"></div>
          <div id="fb-customer-chat" className="fb-customerchat">
          </div>
          {/* <Script strategy='afterInteractive' dangerouslySetInnerHTML={{
            __html: `
             var chatbox = document.getElementById('fb-customer-chat');
             chatbox.setAttribute("page_id", "544092155705817");
             chatbox.setAttribute("attribution", "biz_inbox");
             `}}></Script>
        <Script strategy='afterInteractive' dangerouslySetInnerHTML={{
            __html: `
             window.fbAsyncInit = function() {
                FB.init({
                  appId      : '752109230004890',
                  cookie     : true,
                  xfbml            : true,
                  version          : 'v17.0'
                });
              };

              (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));
        `}}></Script> */}
        </div>

      }
    </div>
  );
};

export default DesktopHeader;
